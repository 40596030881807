<header>
  <mat-toolbar class="api-toolbar">
    <div class="md-toolbar-tools">
      <button mat-icon-button aria-label="Menu">
        <mat-icon>
          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
            <path fill="#FFF" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
          </svg>
        </mat-icon>
      </button>

      <div class="api-toolbar-title" flex="" md-truncate="">IHUBApp API v1.0</div>

      <button mat-icon-button aria-label="Buy us a beer">
        <mat-icon>
          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
            <path
              fill="#f28e1c"
              d="M9.5 3C7.56 3 5.85 4.24 5.23 6.08C3.36 6.44 2 8.09 2 10C2 12.21 3.79 14 6 14V22H17V20H20C20.55 20 21 19.55 21 19V11C21 10.45 20.55 10 20 10H18V8C18 5.79 16.21 4 14 4H12.32C11.5 3.35 10.53 3 9.5 3M9.5 5C10.29 5 11.03 5.37 11.5 6H14C15.11 6 16 6.9 16 8H12C10 8 9.32 9.13 8.5 10.63C7.68 12.13 6 12 6 12C4.89 12 4 11.11 4 10C4 8.9 4.89 8 6 8H7V7.5C7 6.12 8.12 5 9.5 5M17 12H19V18H17Z"
            />
          </svg>
        </mat-icon>
      </button>

      <button mat-icon-button aria-label="Report Bugs">
        <mat-icon>
          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
            <path
              fill="#b13f44"
              d="M12,5C12.74,5 13.47,5.1 14.15,5.29L15.78,2.46C16.06,2 16.67,1.82 17.15,2.1C17.63,2.37 17.79,3 17.5,3.46L16,6.07C17.22,6.78 18.23,7.79 18.93,9H5.07C5.77,7.79 6.78,6.78 8,6.07L6.5,3.46C6.21,3 6.37,2.37 6.85,2.1C7.33,1.82 7.94,2 8.22,2.46L9.85,5.29C10.53,5.1 11.26,5 12,5M20,13C20,17.17 16.82,20.59 12.75,20.97V10.5H19.6C19.86,11.29 20,12.13 20,13M4,13C4,12.13 4.14,11.29 4.4,10.5H11.25V20.97C7.18,20.59 4,17.17 4,13Z"
            />
          </svg>
        </mat-icon>
      </button>

      <button mat-icon-button aria-label="More">
        <mat-icon>
          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
            <path
              fill="#FFF"
              d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"
            />
          </svg>
        </mat-icon>
      </button>
    </div>
  </mat-toolbar>
</header>
