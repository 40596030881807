import { ContentTypes } from './content.enums';

export enum LoginError {
  InvalidSlug = 'INVALID_SLUG',
  InvalidCredentials = 'INVALID_CREDENTIALS',
  UserNotFound = 'USER_NOT_FOUND',
  AdminLoginNotAllowed = 'ADMIN_LOGIN_NOT_ALLOWED',
  UserLoginDisabled = 'USER_LOGIN_DISABLED',
  AuthenticatorCodeRequired = 'AUTHENTICATOR_CODE_REQUIRED',
  InvalidEmailLoginCode = 'INVALID_EMAIL_LOGIN_CODE',
  InvalidVerificationCode = 'INVALID_VERIFICATION_CODE',
  InvalidAuthenticatorCode = 'INVALID_AUTHENTICATOR_CODE',
  InsufficientPermissions = 'INSUFFICIENT_PERMISSIONS'
}

export enum ImportStep {
  SelectFile = 'selectFile',
  ColumnMapping = 'selectColumns',
  SelectUsers = 'selectUsers',
  Confirm = 'confirm'
}

export enum HubConnectionState {
  /** The hub connection is disconnected. */
  Disconnected = 'Disconnected',
  /** The hub connection is connecting. */
  Connecting = 'Connecting',
  /** The hub connection is connected. */
  Connected = 'Connected',
  /** The hub connection is disconnecting. */
  Disconnecting = 'Disconnecting',
  /** The hub connection is reconnecting. */
  Reconnecting = 'Reconnecting'
}

export enum PlanType {
  Trial = -1,
  Free,
  Lite,
  Standard,
  Premium,
  Enterprise
}

export enum Permission {
  AccessBuilder = 'AccessBuilder',
  ManagePermissions = 'ManagePermissions',

  UsersInviteEdit = 'UsersInviteEdit',
  UserSuspend = 'UserSuspend',

  ChannelsCreate = 'ChannelsCreate',
  CategoriesCreate = 'CategoriesCreate',

  ManageContact = 'ManageContact',
  ManageUserUploads = 'ManageUserUploads',
  ManageCompanyProfile = 'ManageCompanyProfile',
  ManageAppSettings = 'ManageAppSettings',
  ManageIntegrations = 'ManageIntegrations',

  ManageMerchantAccounts = 'ManageMerchantAccounts',
  ManageTransferCreate = 'ManageTransferCreate',
  ManageBilling = 'ManageBilling',
  ManageGive = 'ManageGive',

  ViewAnalytics = 'ViewAnalytics',
  ViewDonations = 'ViewDonations',
  ViewPaymentsSubscriptions = 'ViewPaymentsSubscriptions',
  ViewTransferBalance = 'ViewTransferBalance',

  LiveChat = 'LiveChat',

  ManagePostTypes = 'ManagePostTypes',
  ManageUserTags = 'ManageUserTags'
}

export enum ChannelPermission {
  AccessBuilder = 'AccessBuilder',
  PostsCreateEdit = 'PostsCreateEdit',
  PostsEditOther = 'PostsEditOther',
  PostsPublishScheduleArchive = 'PostsPublishScheduleArchive',
  ModerateComments = 'ModerateComments',

  PostsAddNews = 'PostsAddNews',
  PostsAddStory = 'PostsAddStory',
  PostsAddAlbum = 'PostsAddAlbum',
  PostsAddPoll = 'PostsAddPoll',
  PostsAddGetInvolved = 'PostsAddGetInvolved',
  PostsAddEvent = 'PostsAddEvent',
  PostsAddSlider = 'PostsAddSlider',
  PostsAddForm = 'PostsAddForm',
  PostsAddStatistics = 'PostsAddStatistics',

  ChannelEdit = 'ChannelEdit',
  ChannelInviteEditUsers = 'ChannelInviteEditUsers',
  ChannelRoleCreateEdit = 'ChannelRoleCreateEdit',

  TrustComments = 'TrustComments',
  LockChannel = 'LockChannel'
}

export enum StockPhotoType {
  Pexels = 'pexels',
  Unsplash = 'unsplash',
  Pixabay = 'pixabay'
}

export enum CustomHostNameStatus {
  WaitingForUserConfirmation = 'WaitingForUserConfirmation',
  WaitingForDNSPropagation = 'WaitingForDNSPropagation',
  Error = 'Error',
  FailedToCreateCertificate = 'FailedToCreateCertificate',
  CreatingCertificate = 'CreatingCertificate',
  Ready = 'Ready'
}

export enum StorageKeys {
  AppStorage = 'appStorage',
  Config = 'config',
  LastUpdate = 'lastUpdate',
  CacheVersion = 'cacheVersion',
  CurrentUser = 'currentUser',
  LastAthDisplay = 'lastAthDisplay',
  Members = 'members',
  Channels = 'channels',
  SearchHistory = 'searchHistory',
  Widgets = 'widgets',
  AffiliateCode = 'affiliateCode',
  EmojiData = 'emojiData',
  Miscellaneous = 'miscellaneous',
  GuestData = 'guestData',
  ColorHistory = 'colorHistory'
}

export type CommentDraftKey = `comment_${ContentTypes}_${number}`;

export enum ImportColumnType {
  None = 0,
  FirstName = 1,
  LastName = 2,
  Email = 3,
  Phone = 4,
  Username = 5
}

export enum ImportColumnTypeName {
  None = 'Ignored',
  FirstName = 'First Name',
  LastName = 'Last Name',
  Email = 'Email',
  Phone = 'Phone',
  Username = 'Username'
}

export enum AppType {
  Normal = 'normal'
}

export enum SurveyQuestionType {
  MultipleChoice = 1,
  Checkbox = 2,
  ShortAnswer = 3
}

export enum SurveyAnonymousType {
  Public = 1,
  Optional = 2,
  Private = 3
}

export enum EmailInUseNoPassword {
  EmailOk = 0,
  EmailInUse = 1,
  EmailNoPassword = 2
}

export enum InProgressKeys {
  pollLoad = 'pollLoad',
  pollSubmit = 'pollSubmit'
}

export enum UserRoleEnum {
  Owner = 0,
  Administrator = 1
}

export enum SocialFeedType {
  Facebook = 1,
  Twitter = 2,
  GooglePlus = 3,
  Instagram = 4,
  Vimeo = 5,
  YouTube = 6
}

export enum UserCommentState {
  Approved = 1,
  Pending = 2,
  Spam = 3,
  Trash = 4,
  Delete = 5
}

export enum DateRangeState {
  Future = 1,
  InProgress = 0,
  Past = -1
}

export enum EmailInUse {
  Ok = 0,
  EmailInUse = 1,
  EmailInUseNoPassword = 2
}

export enum IntersectionStatus {
  Intersecting = 'Intersecting',
  Pending = 'Pending',
  NotIntersecting = 'NotIntersecting'
}

export enum Products {
  Setup = 'Setup',
  Builder = 'Builder',
  App = 'App',
  Bridge = 'Bridge',
  Api = 'Api'
}

export enum MenuItemType {
  Link = 'link',
  Header = 'header',
  Divider = 'divider',
  MyChannels = 'myChannels',
  Install = 'install',
  Parent = 'parent'
}

export enum UserRoleId {
  BridgeOfficer = -2,
  BridgeCrew = -1,
  Owner = 0,
  Administrator = 1,
  Member = 999
}

export enum MessageFolderTypeId {
  Inbox = 1,
  Sent = 2,
  Trash = 4
}

export enum VideoProviders {
  Youtube = 1,
  Vimeo = 2
}

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export namespace Keyboard {
  /**
   * Keys for KeyboardEvent.key
   */
  export enum Keys {
    Backspace = 'Backspace',
    Tab = 'Tab',
    Enter = 'Enter',
    Shift = 'Shift',
    Control = 'Control',
    Alt = 'Alt',
    Pause = 'Pause',
    CapsLock = 'CapsLock',
    Escape = 'Escape',
    Space = '',
    PageUp = 'PageUp',
    PageDown = 'PageDown',
    End = 'End',
    Home = 'Home',
    ArrowLeft = 'ArrowLeft',
    ArrowUp = 'ArrowUp',
    ArrowRight = 'ArrowRight',
    ArrowDown = 'ArrowDown',
    PrintScreen = 'PrintScreen',
    Insert = 'Insert',
    Delete = 'Delete',
    Digit0 = '0',
    Digit1 = '1',
    Digit2 = '2',
    Digit3 = '3',
    Digit4 = '4',
    Digit5 = '5',
    Digit6 = '6',
    Digit7 = '7',
    Digit8 = '8',
    Digit9 = '9',
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
    H = 'H',
    I = 'I',
    J = 'J',
    K = 'K',
    L = 'L',
    M = 'M',
    N = 'N',
    O = 'O',
    P = 'P',
    Q = 'Q',
    R = 'R',
    S = 'S',
    T = 'T',
    U = 'U',
    V = 'V',
    W = 'W',
    X = 'X',
    Y = 'Y',
    Z = 'Z',
    a = 'a',
    b = 'b',
    c = 'c',
    d = 'd',
    e = 'e',
    f = 'f',
    g = 'g',
    h = 'h',
    i = 'i',
    j = 'j',
    k = 'k',
    l = 'l',
    m = 'm',
    n = 'n',
    o = 'o',
    p = 'p',
    q = 'q',
    r = 'r',
    s = 's',
    t = 't',
    u = 'u',
    v = 'v',
    w = 'w',
    x = 'x',
    y = 'y',
    z = 'z',
    Meta = 'Meta',
    ContextMenu = 'ContextMenu',
    AudioVolumeMute = 'AudioVolumeMute',
    AudioVolumeDown = 'AudioVolumeDown',
    AudioVolumeUp = 'AudioVolumeUp',
    F1 = 'F1',
    F2 = 'F2',
    F3 = 'F3',
    F4 = 'F4',
    F5 = 'F5',
    F6 = 'F6',
    F7 = 'F7',
    F8 = 'F8',
    F9 = 'F9',
    F10 = 'F10',
    F11 = 'F11',
    F12 = 'F12',
    NumLock = 'NumLock',
    ScrollLock = 'ScrollLock',
    Semicolon = ';',
    Equal = '=',
    Comma = ',',
    Minus = '-',
    Period = '.',
    Slash = '/',
    Backquote = '`',
    BracketLeft = '[',
    Backslash = '\\',
    BracketRight = ']',
    Quote = "'",
    Tilde = '~',
    Exclamation = '!',
    At = '@',
    Sharp = '#',
    Dollar = '$',
    Percent = '%',
    Caret = '^',
    Ampersand = '&',
    Asterisk = '*',
    ParenthesisLeft = '(',
    ParenthesisRight = ')',
    Underscore = '_',
    Plus = '+',
    OpenBrace = '{',
    CloseBrace = '}',
    Pipe = '|',
    Colon = ':',
    Quote2 = '"',
    AngleBracketLeft = '<',
    AngleBracketRight = '>',
    QuestionMark = '?'
  }
}

export enum IntlTelInputValidationError {
  IS_POSSIBLE = 0,
  INVALID_COUNTRY_CODE = 1,
  TOO_SHORT = 2,
  TOO_LONG = 3,
  IS_POSSIBLE_LOCAL_ONLY = 4,
  INVALID_LENGTH = 5
}
