import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ENVIRONMENT_PRODUCT } from '@ih/constants';
import { Products } from '@ih/enums';
import { environment } from '../environments/environment';
import { HomeComponent } from './home/home.component';
export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
      FormsModule,
      MatToolbarModule,
      MatButtonModule,
      MatIconModule,
      ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
    ),
    { provide: ENVIRONMENT_PRODUCT, useValue: Products.Api },
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter([{ path: '', component: HomeComponent, pathMatch: 'full' }]),
    provideAnimations()
  ]
};
