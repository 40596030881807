import { InjectionToken } from '@angular/core';
import { MenuItemType, Products } from '@ih/enums';
import { Menu } from '@ih/interfaces';

export const APP_THEME_STYLE_TAG_ID = 'appTheme';

export const SLUG_MIN_LENGTH = 3;
export const SLUG_MAX_LENGTH = 61;

export const DEFAULT_PRIMARY_COLOR = '#4c7ca8';
export const DEFAULT_ACCENT_COLOR = '#e35e14';
export const DEFAULT_NAV_TEXT_COLOR = '#666666';

export const WINDOW = new InjectionToken('Window');
export const ENVIRONMENT_PRODUCT = new InjectionToken<Products>('ENVIRONMENT_PRODUCT');

export const FREE_PRODUCT_IDS = ['free', 'trial'];

export const POST_DETAIL_ROUTE_PREFIXES = {
  posts: 'postId',
  polls: 'pollId',
  news: 'newsId',
  stories: 'storyId',
  albums: 'albumId'
};

export const DEFAULT_FORE_COLOR = '#ffffff';
export const DEFAULT_BACK_COLOR = '#0000ff';

export const QRCODE_SIZE = 512;
export const QRCODE_ICON_SIZE_PERCENTAGE: number = 18 / 100;
export const QRCODE_ICON_SIZE: number = QRCODE_SIZE * QRCODE_ICON_SIZE_PERCENTAGE;
export const QRCODE_ICON_BORDER_SIZE: number = QRCODE_ICON_SIZE * 0.1;

export const EMAIL_REGEX = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
export const EMOJI_REGEX = /:[a-zA-Z0-9-_+]+:(:skin-tone-[2-6]:)?/g;

export const REMOVE_HTML_REGEX = /<[^>]+>/gm;
export const ALPHANUMERIC_REGEX = /^[a-z0-9]+/i;
export const EMOJI_UNICODE_REGEX =
  /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])$/g;

export const CUSTOM_EVENTS = {
  APP_START_COMPLETE: 'appStartComplete',
  CHANNEL_CHANGED: 'channelChanged',
  ROUTE_CHANGED: 'routeChanged',
  ACCOUNT: {
    LOGIN: 'userLogin',
    LOGOUT: 'userLogout',
    LOGIN_FAILED: 'userLoginFailed'
  }
};

export const HASHTAG_REGEX = /\B#\w{2,200}(?:\b|$)/g;

export const RESERVED_SLUGS_REGEX = [
  /^about$/i,
  /^account(?:s)?$/i,
  /^admin(?:istrator)?$/i,
  /^affiliate(?:s)?$/i,
  /^api$/i,
  /^app(?:s)?$/i,
  /^archive$/i,
  /^assets?$/i,
  /^auth(?:entication|orize|orization)?$/i,
  /^backup(?:s)?$/i,
  /^beta$/i,
  /^billing$/i,
  /^blog$/i,
  /^bridge$/i,
  /^build(?:er)?$/i,
  /^business(?:es)?$/i,
  /^calendar$/i,
  /^campaigns?$/i,
  /^careers?$/i,
  /^cart$/i,
  /^categor(?:y|ies)?$/i,
  /^cdn$/i,
  /^channels$/i,
  /^checkout$/i,
  /^clients?(?:-portal)?$/i,
  /^community$/i,
  /^config(?:uration)?$/i,
  /^connect(?:ion)?$/i,
  /^contact(?:us|-us|s)?$/i,
  /^crm$/i,
  /^customize$/i,
  /^dashboard$/i,
  /^data$/i,
  /^demo$/i,
  /^deploy$/i,
  /^design$/i,
  /^dev(?:elopment|elopers?)?$/i,
  /^directory$/i,
  /^dl$/i,
  /^doc(?:s|ument(?:ation|s)?)?$/i,
  /^docs$/i,
  /^downloads?$/i,
  /^eula$/i,
  /^extensions?$/i,
  /^external$/i,
  /^faq$/i,
  /^feed(?:s|back)$/i,
  /^files?$/i,
  /^forum$/i,
  /^ftp$/i,
  /^git$/i,
  /^go$/i,
  /^help$/i,
  /^helpdesk$/i,
  /^home$/i,
  /^host(?:ing|s)?$/i,
  /^https?$/i,
  /^i?hub$/i,
  /^images?$/i,
  /^imap$/i,
  /^import$/i,
  /^index$/i,
  /^info(?:ormation)?$/i,
  /^inquir(?:y|ies)?$/i,
  /^integrations?$/i,
  /^internal$/i,
  /^investors?$/i,
  /^invoice(?:s)?$/i,
  /^jobs?$/i,
  /^kb$/i,
  /^landing(?:-page|page)?$/i,
  /^legal$/i,
  /^license$/i,
  /^log(?:ger|in|out|s|-in|-out)?$/i,
  /^mail(?:ing)?$/i,
  /^mail$/i,
  /^manage$/i,
  /^map$/i,
  /^marketing$/i,
  /^media$/i,
  /^message(?:s|ing)?$/i,
  /^metrics?$/i,
  /^mobile$/i,
  /^mx$/i,
  /^my$/i,
  /^news(?:letters?)?$/i,
  /^news$/i,
  /^notifications?$/i,
  /^oauth(?:2)?$/i,
  /^offers?$/i,
  /^offline$/i,
  /^online$/i,
  /^orders?$/i,
  /^pages?$/i,
  /^partner(?:s)?$/i,
  /^password$/i,
  /^payment(?:s)?$/i,
  /^plans?$/i,
  /^plugins?$/i,
  /^pop$/i,
  /^portal$/i,
  /^post(?:s)?$/i,
  /^pricing$/i,
  /^privacy$/i,
  /^profile(?:s)?$/i,
  /^projects?$/i,
  /^public$/i,
  /^register$/i,
  /^reports?$/i,
  /^reset(?:-password|password)?$/i,
  /^rss$/i,
  /^sales$/i,
  /^search$/i,
  /^services?$/i,
  /^security$/i,
  /^settings?$/i,
  /^shop(?:ping)?$/i,
  /^signup$/i,
  /^site(?:s)?$/i,
  /^sms$/i,
  /^social$/i,
  /^source$/i,
  /^staging$/i,
  /^store(?:s)?$/i,
  /^support$/i,
  /^terms$/i,
  /^test(?:ing)?$/i,
  /^themes?$/i,
  /^tools?$/i,
  /^track(?:ing)?$/i,
  /^translate$/i,
  /^translations?$/i,
  /^trending$/i,
  /^unsubscrib(?:e|es|ed|ing)?$/i,
  /^update$/i,
  /^upload$/i,
  /^user(?:s)?$/i,
  /^video(?:s)?$/i,
  /^web(?:site|sites|master|mail|inars?)?$/i,
  /^widget(?:s)?$/i,
  /^work(?:s)?$/i,
  /^www$/i
];

export const APP_THEME_TAG_ID = 'appTheme';
export const JSON_LD_SCRIPT_TAG_ID = 'json-ld';

export const DEFAULT_GMAP_OPTIONS: google.maps.MapOptions = {
  center: { lat: 37.421995, lng: -122.084092 },
  zoom: 17,
  mapTypeId: 'roadmap',
  gestureHandling: 'cooperative',
  mapTypeControlOptions: {
    style: 1, // google.maps.MapTypeControlStyle.HORIZONTAL_BAR
    position: 6 // google.maps.ControlPosition.LEFT_BOTTOM
  },
  scrollwheel: false,
  streetViewControl: false
};

export const EMOJI_DATASOURCE_GOOGLE_URL = 'https://cdnjs.cloudflare.com/ajax/libs/emoji-datasource-google/14.0.0';
export const EMOJI_DATASOURCE_APPLE_URL = 'https://cdnjs.cloudflare.com/ajax/libs/emoji-datasource-apple/14.0.0';

export const MAX_IMAGE_PIXEL_SIZE = 4096;
export const MAX_IMAGE_FILE_SIZE = 30 * 1024 * 1024; // 30MB

export const NO_LABEL_POSTTYPEID = 0;

export const contentArrayProperties = [
  'channels',
  'photos',
  'ctas',
  'postPhotos',
  'tasks',
  'modules',
  'questions',
  'imgAlts'
];

export const DEFAULT_MENU: Menu = {
  version: '1.0.0',
  showInstall: true,
  sideNav: {
    home: {
      index: 1,
      title: 'Home',
      url: '/home',
      icon: 'mdi-home',
      type: MenuItemType.Link,
      svg: '<svg id="mdi-home" viewBox="0 0 24 24"><path d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z" /></svg>'
    },
    contact: {
      index: 3,
      title: 'Contact',
      url: '/contact',
      icon: 'mdi-contact-mail',
      svg: '<svg id="mdi-contact-mail" viewBox="0 0 24 24"><path d="M21,8V7L18,9L15,7V8L18,10M22,3H2A2,2 0 0,0 0,5V19A2,2 0 0,0 2,21H22A2,2 0 0,0 24,19V5A2,2 0 0,0 22,3M8,6A3,3 0 0,1 11,9A3,3 0 0,1 8,12A3,3 0 0,1 5,9A3,3 0 0,1 8,6M14,18H2V17C2,15 6,13.9 8,13.9C10,13.9 14,15 14,17M22,12H14V6H22" /></svg>'
    },
    explore_channels: {
      index: 4,
      title: 'Explore Channels',
      url: '/channels',
      icon: 'mdi-pound',
      requireAuth: false,
      svg: '<svg id="mdi-pound" viewBox="0 0 24 24"><path d="M5.41,21L6.12,17H2.12L2.47,15H6.47L7.53,9H3.53L3.88,7H7.88L8.59,3H10.59L9.88,7H15.88L16.59,3H18.59L17.88,7H21.88L21.53,9H17.53L16.47,15H20.47L20.12,17H16.12L15.41,21H13.41L14.12,17H8.12L7.41,21H5.41M9.53,9L8.47,15H14.47L15.53,9H9.53Z" /></svg>',
      type: MenuItemType.Link
    },
    messages: {
      index: 5,
      title: 'Messages',
      singularTitle: 'Message',
      url: '/messages',
      icon: 'mdi-email',
      badge: 'unread_messages',
      requireAuth: true,
      svg: '<svg id="mdi-email" viewBox="0 0 24 24"><path d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z" /></svg>'
    },
    my_starred_channels: {
      index: 6,
      title: 'My starred channels',
      url: '',
      icon: 'mdi-heart-outline',
      svg: '<svg id="mdi-heart-outline" viewBox="0 0 24 24"><path d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" /></svg>',
      type: MenuItemType.MyChannels,
      requireAuth: true
    },
    save_this_app: {
      index: 7,
      title: 'Save this app',
      type: MenuItemType.Install,
      icon: 'mdi-cellphone-arrow-down',
      svg: '<svg id="mdi-download" viewBox="0 0 24 24"><path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" /></svg>'
    }
  }
};

export const SOCIAL_PLATFORMS: {
  [key: string]: {
    deepLink: string;
    fallbackLink: string;
  };
} = {
  facebook: {
    deepLink: `fb://share?app_id={this.appId}&link=`,
    fallbackLink: `https://www.facebook.com/dialog/share?app_id={this.appId}&display=popup&href=`
  },
  twitter: {
    deepLink: 'twitter://post?message=',
    fallbackLink: 'https://twitter.com/intent/tweet?text='
  },
  linkedin: {
    deepLink: 'linkedin://shareArticle?mini=true&url=',
    fallbackLink: 'https://www.linkedin.com/sharing/share-offsite/?url='
  }
};
